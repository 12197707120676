<template>
    <div class="row">
        <div class="question-title">
            <span>{{getTime()}}</span>
            <span v-if="$store.state.paperLanguage == 'zh'">{{getItem('exam_name')}}</span>
            <span v-if="$store.state.paperLanguage == 'en'">{{getItem('exam_name_en')}}</span>
        </div>
        <div class="modular-list">
            <table>
                <tr v-for="(item,index) in questionList" :key="index">
                    <td> <span :class="item.active?'active':''" @click="questionActive(index)"> <i></i> </span> </td>
                    <td>不规则拍子</td>
                    <td> <i class="des">乐器法综合知识，减音程，半音阶，单复拍子转换，单音程，填空题，复拍子转单拍子，小调，小调音阶</i> </td>
                    <td>总题数：20</td>
                    <td>
                        <div class="tishu">
                            <input type="text" v-model.number="num">
                            <div class="tishu-right">
                                <div class="el-icon-arrow-up" @click="num++"></div>
                                <div class="el-icon-arrow-down" @click="num--"></div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="modular-botton">
            <div class="modular-botton-left">
                已选择 {{activeNum}} 题
            </div>
            <div class="modular-botton-right">
                <q-btn class="modular-botton-right-btn" unelevated  flat label="确定" @click="$router.go(-1)"  />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "choiceType",
        data(){
            return{
                num:1,
                questionList:[{
                    id:0,
                    active:true
                },{
                    id:0,
                    active:false
                }]
            }
        },
        methods:{
            questionActive(index){
                this.questionList[index].active = !this.questionList[index].active
            }
        },
        computed:{
            activeNum(){
                let num = 0
                this.questionList.forEach((item)=>{
                    if (item.active){
                        num++
                    }
                })
                return num
            }
        }
    }
</script>

<style scoped lang="less">
    .question-title{
        display: flex;
        align-items: center;
        padding: 0 37px;
        margin-top: 27px;
        span{
            display: inline-block;
            font-size: 20px;
            color: #ED711C;
            margin-right: 30px;
        }
    }
    .modular-list{
        width: 100%;
        height: 419px;
        overflow: auto;
        padding: 0 33px;
        padding-top: 20px;
        table{
            width: 100%;
            tr{
                width: 100%;
                height: 50px;
                background: #F4F4F4;
                border-radius: 20px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 31px;
                td{
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        width: 22px;
                        height: 22px;
                        border: 1px solid #626262;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover{
                            border: 1px solid #ED711C;
                        }
                        i{
                            display: inline-block;
                            width: 90%;
                            height: 90%;
                            background: #ED711C;
                            transition: all 0.3s;
                            opacity: 0;
                        }
                        transition: all 0.3s;
                    }
                    span.active{
                        border: 1px solid #ED711C;
                    }
                    span.active i{
                        display: inline-block;
                        opacity: 1;
                    }
                    a{
                        color: #ED711C;
                    }
                    .des{
                        font-size: 14px;
                        color: #666666;
                    }
                }
            }
        }
    }
    .modular-botton{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 69px;
        padding-top: 20px;
        border-top: 2px solid #ED711C;
        .modular-botton-left{
            font-size: 18px;
            color: #ED711C;
            margin-left: 59px;
        }
        .modular-botton-right-btn{
            width: 86px;
            height: 34px;
            background: #ED711C;
            color: #ffffff;
            border-radius: 13px;
            margin-right: 70px;
            font-size: 18px;
            /deep/ .q-btn__wrapper{
                min-height: auto;
            }
        }
    }
    .tishu{
        display: flex;
        align-items: center;
        input{
            width: 37px;
            height: 28px;
            border: 2px solid #666666;
            text-align: center;
        }
        .tishu-right{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            div{
                cursor: pointer;
                &:hover{
                    color: #ED711C;
                }
            }
        }
    }

</style>